import React from "react";
import { Link } from "react-router-dom";
import Header from "../component/Header";
import Container from "../component/Container";
import firebase from "../config/firebase";
import defaultLogo from "../asset/logo_b1.png";
import "../style/Product.css";
import productImg from '../asset/product_page.jpg';
import LoadingIndicator from "../component/LoadingIndicator";

export default class Product extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      productCat: [],

      loading: true
    };
  }

  componentDidMount() {
    this.getProductCategory();
    setTimeout(() => {
      this.setState({ loading: false })
    }, 2000);
  }

  getProductCategory() {
    firebase
      .firestore()
      .collection("ProductCategory")
      .orderBy("orderBy")
      .onSnapshot((snapshot) => {
        let productCat = snapshot.docs.map((doc) => doc.data());
        this.setState({ productCat: productCat }, () => {
          // console.log("product cate", this.state.productCat);
        });
      });
  }

  // fetchProduct = () => {
  //   try {
  //     const products = commerce.products.list();
  //     console.log("fetching products", products.data);
  //     this.setState({ product: products });
  //   } catch (error) {
  //     console.log("Error fetching products", error);
  //   }
  // };

  render() {
    return (
      <Container>
        {this.state.loading ? <LoadingIndicator /> : <>
          <Header clearImg newHeaderImg={productImg} />
          <section className="category">
            <h2>Categories</h2>
            {/* <div>
            <Link
              to="/products/all"

            >
              <button>
                Show All Product
              </button>
            </Link>
          </div> */}
            <div className="category-container">
              {this.state.productCat.map((item, index) => {
                return (
                  <div key={index} className="category-col">
                    {/* <Link to={"/product/" + item.slug}> */}
                    <Link
                      to={{
                        pathname: "/product/" + item.slug,
                        state: { item: item },
                      }}
                    >
                      <button className="btn-next" type="submit">
                        <div className="card category-card">
                          <div className="card-body">
                            <img
                              src={item.img === "" ? defaultLogo : item.img}
                              alt={item.name}
                              className="category_img"
                            />
                            {/* <div className="category-title"> */}
                            {/* {item.name + " (" + item.id + ")"} */}
                            {/* {item.name }
                          </div> */}
                          </div>
                        </div>
                      </button>
                    </Link>
                  </div>
                );
              })}
            </div>
          </section>
        </>}

      </Container>
    );
  }
}
