import React from 'react';
import NavBar from '../component/NavBar';
import '../style/NotFound.css';
import Footer from '../component/Footer';
import { Link } from 'react-router-dom';

import ComingSoon from '../asset/coming_soon.jpg'
import errorNotFound from '../asset/BH 404bb.jpg'

const localhost = 'http://localhost:3000/';
const lazadaLink = 'https://www.lazada.com.my/shop/ban-heang?spm=a2o4k.searchlist.card.1.346c7b63Ay60iy&from=onesearch_brand_131009'

export default class NotFound extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        };
    }

    onClick(pLink) {
        // console.log(pLink)
        const anchor = document.createElement("a");
        anchor.href = pLink;
        anchor.target = "_blank";
        anchor.click();
      }
    



    render() {
        return (
            <div>
                {/* <NavBar /> */}
                <section>
                    <div className='container notfound_container'>
                        <div className='img_container'>
                             <img src={errorNotFound} className="img" />
                        </div>
                   
                        {/* <h2>Page Not Found</h2> */}
                        <div className="buyButton">
                        <button
                          type="button"
                          onClick={() => this.onClick(lazadaLink)}
                          className="btn btn-info">
                          Check Out Our Lazada Page
                        </button>
                      </div>
                    </div>
                </section>
                {/* <Footer /> */}
            </div>
        )
    }
}
