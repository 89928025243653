import React from "react";
import "../style/Header.css";
// import '../index.css'
import headerImg from "../asset/about_header.jpeg";
const localhost = "http://localhost:3000/";

export default class Header extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,

      newHeaderImg: props.newHeaderImg !== undefined ? props.newHeaderImg : headerImg
    };
  }

  render() {
    const { img, title, children, clearImg, newHeaderImg } = this.props;
    // console.log('new header img', newHeaderImg)
    return (
      <header className="header">
        {/* <div className="header_container"> */}
          <div className={`${!clearImg && "header_container-bg"}`}>
            <img src={this.state.newHeaderImg} alt="tbb poster" />
          </div>
          {/* <div className="header_content">
            <h2>{title}</h2>
            <p>{children}</p>
          </div> */}
        {/* </div> */}
      </header>
    );
  }
}
