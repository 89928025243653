import React from "react";
import NavBar from "../component/NavBar";
import "../style/About.css";
import Footer from "../component/Footer";
import Header from "../component/Header";

import About_1 from "../asset/about_1.png";
import About_2 from "../asset/about_2.png";
import About_3 from "../asset/about_us_text_cn.png";
import About_4 from "../asset/about_4.png";
import headerImg from "../asset/Outlet/Outlet_MR.jpg";

import LoadingIndicator from "../component/LoadingIndicator";

const localhost = "http://localhost:3000/";

export default class About extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    }
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ loading: false })
    }, 2000);
  }

  render() {
    return (
      <div>
        <NavBar {...this.props} />

        {this.state.loading ? <LoadingIndicator /> :
          <>
            <Header clearImg />
            <div className="about_container">
              <div className="container about_story_left">
                <div className="about_section-content">
                  <img src={About_1} className="img" />
                  {/*                            
                            <h1>
                                我们的故事
                            </h1> */}
                  <p>
                    Mr. Leow Tit Ban, the founder of Ban Heang, has transformed a
                    family-style pastry business into a renowned Malaysian specialty
                    store. The journey of entrepreneurial endeavors, embarked upon
                    in 1997, unfolds as follows:
                  </p>
                  <p>
                    In 1997, at the young age of twenty, Mr. Leow Tit Ban embarked
                    on his entrepreneurial journey with the accompaniment of his
                    mother, previously involved in music, and three employees. The
                    business was initiated in a family business. At that time, Ban
                    Heang, from research and development, production, packaging,
                    hygiene, wholesale, delivery, to market operations, was all
                    managed single-handedly by Mr. Leow Tit Ban.
                  </p>

                  <p>
                    As business grew in 1999, Ban Heang relocated to Malacca Street
                    in Penang under strategic considerations to further expand its
                    operations.
                  </p>
                  <p>
                    Ban Heang became the first native product shop in Penang to
                    develop a variety of flavors for Tambun biscuits, introducing
                    orange, pandan, coffee, and shrimp flavors. This groundbreaking
                    innovation not only set a precedent in the biscuit industry but
                    also indirectly influenced the healthy competition in Penang's
                    biscuit sector that thrives to this day.
                  </p>
                  <p>
                    In order to establish Ban Heang as a representative Malaysian
                    specialty, Mr. Leow Tit Ban spent five years building from
                    scratch in external settings. He became a senior manager of a
                    retail shop more than twenty branches in the Northern region of
                    Malaysia, which contributed to his acquisition of valuable
                    experience in store management and operational models.
                  </p>
                  <p>
                    In 2009, Mr. Leow Tit Ban decided that the time was ripe and
                    thus resigned from his senior managerial position, utilizing the
                    accumulated five years of experience to propel Ban Heang
                    forward, step by step, towards realizing his initial ambitions.
                  </p>

                  <p>
                    In 2010, Ban Heang relocated its business from Melaka Street to
                    Macalister Road at Georgetown. At that time, the bakery elevated
                    the quality of all its products, aiming for excellence in taste,
                    appearance, and fragrance. Coupled with the continuous
                    enhancement of employee performance, this commitment led to the
                    provision of outstanding customer service. From its humble
                    beginnings with just four individuals, Ban Heang gradually
                    expanded to include over a hundred like-minded partners.
                  </p>

                  <p>
                    In 2018, Ban Heang ventured into franchising, becoming a member
                    of the Malaysian Franchise Association.
                  </p>
                  <p>
                    In 2019, Ban Heang established its own membership system,
                    introducing more benefits to reciprocate the enduring support of
                    its customers.
                  </p>
                  <p>
                    Life is inseparable from coffee, and culture is incomplete
                    without souvenirs. In 2020, Ban Heang embarked on its first
                    cross-industry collaboration with the globally renowned brand,
                    Starbucks.
                  </p>

                  <p>
                    Despite Ban Heang 's current achievements, its original
                    aspiration remains steadfast—to carry forward the culture of
                    gifting in the Chinese tradition and to promote Malaysian
                    specialty souvenirs to every corner of the world.
                  </p>
                  <p>
                    Heartfelt service, stringent quality, innovative thinking, and
                    systematic management constitute the vision of Mr. Leow Tit Ban
                    and his dedicated team for Ban Heang.
                  </p>
                </div>
              </div>
              <div className="container about_story_right">
                <div className="about_section-content">
                  <img src={About_3} className="img_2" />
                  <img src={About_2} className="img_3" />
                  <p>
                    萬香饼家创办人——饶狄万先生，从昔日家庭式制饼业至今成为马来西亚驰名特产专卖店，从1997年一路走来的风雨创业之路：
                  </p>
                  <p>
                    1997年仅有二十岁的他，在母亲曾来音和三位员工的陪同下，以家庭模式来开始经营。
                    当时萬香饼家从研发、生产、包装、卫生、批发、送货及市场经营，都由饶狄万先生一手包办。
                  </p>

                  <p>
                    1999年生意逐渐增加，在生意策略考量下搬迁至槟城马六甲街继续拓展业务
                    。
                  </p>
                  <p>
                    萬香饼家是槟城第一家研发出多种不同口味淡汶饼的饼家，成功研发出
                    橙味、香草味、咖啡味及虾米味的淡汶饼。在当时饼界可说是首开先河的创举，也间接影响了至今槟城饼界百花齐放的良性竞争。
                  </p>
                  <p>
                    为了将萬香饼家成为大马代表性特产，饒狄萬先生用了5年的时间在外由零做起，当上了大马北马区某品牌二十多间门市的高级经理，也在这期间吸取许多宝贵的门市经验及经营模式。
                  </p>
                  <p>
                    2009年饶狄万先生当时觉得时机成熟，毅然辞去高级经理的职位，把5年内累积的经验运用在萬香饼家，力图以自身所长来将当年理想抱负一步一步去实现。
                  </p>

                  <p>
                    2010年萬香饼家搬迁至槟城美食街（中路）即赤石大酒店旁。当时萬香饼家将所有产品提升品质，务求达到色香味俱全。再加上对员工表现要求不断提升，以达到对顾客提供优良的客售服务。从当初仅有四人打拼的萬香饼家慢慢发展至今拥有了百多名志同道合的伙伴们。
                  </p>

                  <p>
                    2018年“萬香饼家”也开拓特许经营模式运作的分店，并注册成为马来西亚特许经营协会会员。
                  </p>

                  <p>
                    2019年“萬香饼家”成立了自己的会员系统制度，推出更多的福利以回馈顾客们一直给予的支持。
                  </p>

                  <p>
                    生活离不开咖啡, 文化也少不了手信。2020年,
                    萬香饼家首度与世界知名品牌星巴克首度跨界合作。
                  </p>

                  <p>
                    纵使如今的“萬香饼家”已稍有成就，但是我们从未忘记初衷，将中华送礼文化传承下去及把马来西亚特色手信推广至世界各地。
                  </p>

                  <p>
                    贴心的服务，严谨的品质，创新的心思，系统的管理，这就是饶狄万先生和一众伙伴们心目中的——萬香饼家。
                  </p>


                </div>
              </div>
            </div>

            <div className="about_image">
              <img src={About_4} />
            </div> </>}

        <Footer />
      </div>
    );
  }
}
