import ProductList from "../component/ProductList";
import commerce from "../lib/commerce";
import React from "react";
import { Link } from "react-router-dom";
import Header from "../component/Header";
import Container from "../component/Container";
import firebase from "../config/firebase";
import defaultLogo from "../asset/logo_b1.png";
import "../style/Product.css";
import { useLocation } from "react-router-dom";
import LoadingIndicator from "../component/LoadingIndicator";


export default class ShowProduct extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      productSlug: '',

      category: [],
      products: [],
      productCat: [],
      latestProduct: [],


      loading: true,
    };
  }

  componentDidMount() {
    //   const { location } = this.props; // Use this.props.location
    //  const data = location.state.item;

    const pathname = window.location.pathname;

    // Split the pathname by slashes
    const parts = pathname.split('/');

    // Extract the "tambun-series" part
    const productSlug = parts[2];

    // Use the productSlug in your component
    // console.log('Product Slug:', productSlug);

    this.getAllProducts(productSlug);

    setTimeout(() => {
      this.setState({ loading: false, productSlug: productSlug })
    }, 2000);

    // console.log('data', data.id);
    // this.setState({ category: data }, () => {

    //   });
    // if(data) {
    //    this.setState({ category: data }, () => {
    //   this.getAllProducts(data.id, productSlug);
    // })
    // } else {
    //   this.getProductCategory(productSlug)
    // }

  }

  // getAllProducts(id, productSlug) {
  //   firebase
  //     .firestore()
  //     .collection("ProductDetails")
  //     .orderBy("orderBy")
  //     // .where("categoryId", "==", id)
  //     .onSnapshot((snapshot) => {
  //       let products = snapshot.docs.map((doc) => doc.data());
  //       this.setState({ products: products }, () => {
  //         console.log("product caterr", this.state.products);
  //         if (id) {
  //           console.log('inn')
  //           this.filterProductById(id)
  //         } else {
  //           console.log('innnnn')
  //           this.filterProductBySlug(productSlug) 
  //         }
  //       });
  //     });
  // }

  getAllProducts(productSlug) {
    firebase
      .firestore()
      .collection("ProductDetails")
      .orderBy("orderBy")
      // .where("categoryId", "==", id)
      .onSnapshot((snapshot) => {
        let products = snapshot.docs.map((doc) => doc.data());
        this.setState({ products: products }, () => {
          // console.log("product caterr", this.state.products);
          this.filterProductBySlug(productSlug)
        });
      });
  }

  // filterProductById(id) {
  //   const filterResult = this.state.products.filter(item => item.categoryId === id)

  //   this.setState({ latestProduct: filterResult }, () => {
  //     console.log('data', this.state.latestProduct);
  //   })

  // }

  filterProductBySlug(slug) {
    const filterResult = this.state.products.filter(item => item.categorySlug === slug)

    this.setState({ latestProduct: filterResult }, () => {
      // console.log('data', this.state.latestProduct);
    })

  }

  onClick(pLink) {
    // console.log(pLink)
    const anchor = document.createElement("a");
    anchor.href = pLink;
    anchor.target = "_blank";
    anchor.click();
  }

  // fetchProduct = () => {
  //   try {
  //     const products = commerce.products.list();
  //     console.log("fetching products", products.data);
  //     this.setState({ product: products });
  //   } catch (error) {
  //     console.log("Error fetching products", error);
  //   }
  // };

  render() {
    const category = this.state.latestProduct.map((i, index) => {
      return i.category
    })
    const itemSlug = this.state.latestProduct.map((i, index) => {
      return i.slug
    })
    const firstCategory = category[0]
    console.log('first cat', itemSlug)
    return (
      <Container>
        {/* <Header title="Our Products"></Header> */}
        {this.state.loading ?
          <LoadingIndicator /> : <>
            <section className="category">
              <h2>{firstCategory}</h2>
              <div className="category-container">
                {this.state.latestProduct.map((item, index) => {
                  return (
                    <div key={index} className="category-col">
                      <div className="card category-card">
                        <div className="card-body">
                          <img
                            src={item.img === "" ? defaultLogo : item.img}
                            alt={item.name}
                            className="category_img"
                          />
                          <div className="productName">
                            <h3>{item.chineseName}</h3>
                            <h3>{item.name}</h3>
                          </div>


                          {/* <button type="button" className="buttonContainer">
                            <Link
                              to={{
                                pathname: "/product/" +  this.state.productSlug + '/' + item.slug,
                                state: { item: item },
                              }}
                              className="linkStyle"  // Add any additional styles if needed
                            >
                              {'Product Details'}
                            </Link>
                          </button> */}

                          {/* <div className="buyButton"> */}
                          <button
                            type="button"
                            onClick={() => this.onClick(item.purchaseLink)}
                            disabled={!item.purchaseLink}
                            className="buttonContainer">
                            {item.purchaseLink ? 'Check Out at Lazada' : 'Available at outlet only'}
                          </button>

                          {/* </div> */}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </section>
          </>}

      </Container>
    );
  }
}
