import React from 'react';
import Logo from '../asset/logo.png';
import LogoB1 from '../asset/logo_b1.png';
import LogoB2 from '../asset/logo_b2.png';
import '../style/NavBar.css';
import { Link, NavLink } from 'react-router-dom';
import { VscThreeBars } from 'react-icons/vsc';
import { MdOutlineClose } from 'react-icons/md';
import ContentImgV2 from '../asset/contentImageHome_v2.jpeg'


export default class NabBar extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {

            isNavShowing: false,

            naviLink: [
                {
                    name: 'Home',
                    path: '/'
                },
                {
                    name: 'About Us',
                    path: '/about'
                },
                {
                    name: 'Products',
                    path: '/product'
                },
                {
                    name: 'Outlets',
                    path: '/outlets'
                },
                {
                    name: 'Career',
                    path: '/contact'
                },
            ]
        }
    }
    render() {
        return (
            <div>
                {/* logo */}
                <Link to='/' className='logo' onClick={() => this.setState({ isNavShowing: false })}>
                    <img className='img' src={LogoB1} alt="Nav Logo" />
                </Link>
                {/* <div className="contentImg">
                    <img src={ContentImgV2} />
                </div> */}
                <nav>
                    <div className='container nav_container'>
                        {/* {this.state.showImage ?  <img className='img' src={LogoB1} alt="Nav Logo" /> : null} */}
                        {/* nav links */}
                        <ul className={`nav_links ${this.state.isNavShowing ? 'show_nav' : 'hide_nav'}`}>
                            {
                                this.state.naviLink.map((items, index) => {
                                    return (
                                        <li key={index}>
                                            <NavLink to={items.path}
                                                className={({ isActive }) => isActive ? 'active-nav' : ''}
                                            >
                                                {items.name}
                                            </NavLink>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                        {/* navi button */}
                        <button className='nav_toggle-btn' onClick={() => this.setState({ isNavShowing: !this.state.isNavShowing })}>
                            {this.state.isNavShowing ? <MdOutlineClose /> : <VscThreeBars />}
                        </button>
                    </div>
                </nav>
            </div>

            // <div>
            //  <Navbar>
            //     <NavbarBrand>
            //         <Link className="logo" to="/">
            //             <img className='logo-img' src={logo} />
            //         </Link>
            //     </NavbarBrand>

            //     <Nav className="ml-auto">
            //         <NavItem>
            //             <NavLink href="/">Home</NavLink>
            //         </NavItem>
            //         <NavItem>
            //             <NavLink>About Us</NavLink>
            //         </NavItem>
            //         <NavItem>
            //             <NavLink>Product</NavLink>
            //         </NavItem>
            //         <NavItem>
            //             <NavLink>Outlet</NavLink>
            //         </NavItem>
            //         <NavItem>
            //             <NavLink>Contact Us</NavLink>
            //         </NavItem>
            //         <NavItem>
            //             <NavLink>Shop Now</NavLink>
            //         </NavItem>
            //     </Nav>
            // </Navbar>
            // </div>


        );
    }
}
