import React from "react";
import NavBar from "../component/NavBar";
import "../style/Contact.css";
import Footer from "../component/Footer";
import Header from "../component/Header";

import { MdEmail } from "react-icons/md";
import { BsMessenger } from "react-icons/bs";
import { IoLogoWhatsapp } from "react-icons/io";

import contactImg from "../asset/contact_us.jpg";
import carrerImg from "../asset/contact_us_carrer.png";
import LoadingIndicator from "../component/LoadingIndicator";

const localhost = "http://localhost:3000/";

export default class Contact extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    }
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ loading: false })
    }, 2000);
  }

  render() {
    return (
      <div>
        <NavBar {...this.props} />

        {this.state.loading ? <LoadingIndicator /> :
          <>
            <Header clearImg newHeaderImg={contactImg} />
            <section className="contact">
              <div className="container contact_container">
                <h2>{"Join Our Team"}</h2>
                <p>
                  {
                    "Career opportunities await, explore your future with us, and be part of something great"
                  }
                </p>

                <button type="button">
                  <a
                    href="https://wa.me/+60164950903"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    Contact Us
                  </a>
                </button>

                {/* <div className='contact_wrapper'>
                            <a href="mailto:info@banheang.com.my" target="_blank" rel="noreferrer noopener"><MdEmail /></a>
                            <a href="https://m.me/banheang.msia" target="_blank" rel="noreferrer noopener"><BsMessenger /></a>
                            <a href="https://wa.me/+60147976088" target="_blank" rel="noreferrer noopener"><IoLogoWhatsapp /></a>
                        </div> */}
              </div>
            </section>
          </>}

        <Footer />
      </div>
    );
  }
}
