import React from 'react';
import NavBar from '../component/NavBar';
import '../style/NotFound.css';
import Footer from '../component/Footer';
import Header from '../component/Header';
import Outlet from '../component/Outlet';
import { Link } from 'react-router-dom';
import headerImg from '../asset/Outlet/Outlet_MR.jpg';

import { outletsPenang } from '../data/data';
import { outletsGenting } from '../data/data';
import { outletsKl } from '../data/data';
import { outletsMalacca } from '../data/data';
import LoadingIndicator from '../component/LoadingIndicator';

const localhost = 'http://localhost:3000/';

export default class Outlets extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
        };
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({ loading: false })
        }, 2000);
    }

    render() {
        return (
            <div>
                <NavBar />

                {this.state.loading ? <LoadingIndicator /> : <>
                    <Header clearImg />
                    <section className='outlets'>
                        <h1>Penang</h1>
                        <div className='container outlets_container'>
                            {outletsPenang.map((i, index) => {
                                return (
                                    <Outlet data={i} key={index} />
                                )
                            })}
                        </div>

                        <h1>Genting Highlands</h1>
                        <div className='container outlets_container'>

                            {outletsGenting.map((a, index) => {
                                return (
                                    <Outlet data={a} key={index} />
                                )
                            })}
                        </div>

                        <h1>Kuala Lumpur</h1>
                        <div className='container outlets_container'>

                            {outletsKl.map((k, index) => {
                                return (
                                    <Outlet data={k} key={index} />
                                )
                            })}
                        </div>

                        <h1>Malacca</h1>
                        <div className='container outlets_container'>

                            {outletsMalacca.map((m, index) => {
                                return (
                                    <Outlet data={m} key={index} />
                                )
                            })}
                        </div>
                    </section>
                </>}



                <Footer />
            </div>
        )
    }
}
