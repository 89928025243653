import React from "react";
import { Link } from "react-router-dom";
import Header from "../component/Header";
import Container from "../component/Container";
import firebase from "../config/firebase";
import defaultLogo from "../asset/logo_b1.png";
import "../style/Product.css";
import productImg from '../asset/product_page.jpg';
import LoadingIndicator from "../component/LoadingIndicator";

export default class ProductDetails extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            product: [],
            details: [],

            loading: true
        };
    }

    componentDidMount() {
        const { id1, id2 } = this.props.match.params;
        const { item } = this.props.location.state;

        this.setState({ loading: false}, () => {
            console.log('item slug', item.slug)
            this.getDetails(item.slug)
        })
    }

    getDetails(productSlug) {
        firebase
          .firestore()
          .collection("ProductDetails")
          .orderBy("orderBy")
          // .where("categoryId", "==", id)
          .onSnapshot((snapshot) => {
            let products = snapshot.docs.map((doc) => doc.data());
            this.setState({ product: products }, () => {
              console.log("product caterr", this.state.product);
              this.filterProductBySlug(productSlug)
            });
          });
      }


  filterProductBySlug(slug) {
    const filterResult = this.state.product.filter(item => item.slug === slug)

    this.setState({ details: filterResult }, () => {
      console.log('data', this.state.latestProduct);
    })

  }

    render() {
        return (
            <Container>
                {this.state.loading ? <LoadingIndicator /> : <>
                <section className="category">
                <img
                            src={this.state.details.img === "" ? defaultLogo : this.state.details.img}
                            alt={this.state.details.name}
                            className="category_img"
                          />
                    
                <h2>{this.state.details.chineseName}</h2>
                                <h2>{this.state.details.name}</h2>

<h4>{this.state.details.chineseDescription}</h4>
<h4>{this.state.details.description}</h4>
                            </section>
                </>}

            </Container>
        );
    }
}
