import { createStore, applyMiddleware, combineReducers } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunkMiddleware from "redux-thunk";

import { en, my, zh, jp } from "../locales/index";

const persistConfig = {
  key: "root",
  storage,
};

// Initial State
const initialState = {
  language: "",
  languageType: "",
  dialData: [],
  formData: "",

  smsExpiry: true,
  lastSmsTime: 0,
  guid: "",

  userData: "",
  userAuth: "",
  childData: "",
};

// Reducer

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "setLanguage":
      return {
        ...state,
        language: action.value,
      };
    case "setDialData":
      return { ...state, dialData: action.value };
    case "setFormData":
      return { ...state, formData: action.value };
    case "setUserData":
      return { ...state, userData: action.value };
    case "setChildData":
      return { ...state, childData: action.value };
    case "setUserAuth":
      return { ...state, userAuth: action.value };
    case "setSmsExpiry":
      return { ...state, smsExpiry: action.value };
    case "setLastSmsTime":
      return { ...state, lastSmsTime: action.value };
    case "setGuid":
      return { ...state, guid: action.value };
    default:
      return state;
  }
};

const persistedReducer = persistReducer(persistConfig, reducer);

const store = createStore(persistedReducer, applyMiddleware(thunkMiddleware));
const persistor = persistStore(store);

// Actions
const setLanguage = (language) => {
  // console.log('langType -', language);
  let newLanguage = "";
  if (language === "zh") {
    newLanguage = zh;
  } else if (language === "my") {
    newLanguage = my;
  } else if (language === "jp") {
    newLanguage = jp;
  } else {
    newLanguage = en;
  }
  return {
    type: "setLanguage",
    value: newLanguage,
  };
};

const setDialData = (dialData) => {
  // console.log(dialData);
  return {
    type: "setDialData",
    value: dialData,
  };
};

const setFormData = (formData) => {
  // console.log(formData);
  return {
    type: "setFormData",
    value: formData,
  };
};

const setChildData = (childData) => {
  // console.log(dialData);
  return {
    type: "setChildData",
    value: childData,
  };
};

const setUserData = (userData) => {
  // console.log("userData --", userData);
  return {
    type: "setUserData",
    value: userData,
  };
};

const setUserAuth = (userAuth) => {
  // console.log("userauth --", userAuth);
  return {
    type: "setUserAuth",
    value: userAuth,
  };
};

const setSmsExpiry = (smsExpiry) => {
  return {
    type: "setSmsExpiry",
    value: smsExpiry,
  };
};

const setLastSmsTime = (lastSmsTime) => {
  return {
    type: "setLastSmsTime",
    value: lastSmsTime,
  };
};

const setGuid = (guid) => {
  return {
    type: "setGuid",
    value: guid,
  };
};

//
// Store...
//

export {
  store,
  persistor,
  setLanguage,
  setDialData,
  setFormData,
  setUserAuth,
  setUserData,
  setChildData,
  setSmsExpiry,
  setLastSmsTime,
  setGuid,
};
