import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyCEv_qlDwznA625SKNRKEZCcGV5ZfQCPCo",
  authDomain: "ban-heang.firebaseapp.com",
  projectId: "ban-heang",
  storageBucket: "ban-heang.appspot.com",
  messagingSenderId: "792856777090",
  appId: "1:792856777090:web:703b9b5f1a64428f95bf9b",
  measurementId: "G-CCRWV7VDNK"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export default firebase;


