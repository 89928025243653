import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import { Provider, connect } from "react-redux";
import store, {
  setLanguage,
} from "./redux/app-redux";

import Home from "./screens/Home";
import About from "./screens/About";
import Contact from "./screens/Contact";
import Outlets from "./screens/Outlets";
import Product from "./screens/Product";
import NotFound from "./screens/NotFound";
import ShowProduct from "./screens/ShowProduct";
import AllProduct from "./screens/AllProduct";
import ProductDetails from "./screens/ProductDetails";


const mapStateToProps = (state) => {
  return {
    language: state.language,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLanguage: (language) => dispatch(setLanguage(language)),
  };
};

function Main() {
  return (
    <Router>
      <Switch>
        <Route
          exact
          path="/"
          component={connect(mapStateToProps, mapDispatchToProps)(Home)}
        />
        <Route
          exact
          path="/about"
          component={connect(mapStateToProps, mapDispatchToProps)(About)}
        />

        <Route
          exact
          path="/product"
          component={connect(mapStateToProps, mapDispatchToProps)(Product)}
        />

        <Route
          exact
          path="/product/:id"
          component={connect(mapStateToProps, mapDispatchToProps)(ShowProduct)}
        />

        <Route
          exact
          path="/product/:id1/:id2"
          component={connect(mapStateToProps, mapDispatchToProps)(ProductDetails)}
        />

        <Route
          exact
          path="/products/all"
          component={connect(mapStateToProps, mapDispatchToProps)(AllProduct)}
        />

        <Route
          exact
          path="/outlets"
          component={connect(mapStateToProps, mapDispatchToProps)(Outlets)}
        />

        <Route
          exact
          path="/contact"
          component={connect(mapStateToProps, mapDispatchToProps)(Contact)}
        />

        <Route
          exact
          path="/*"
          component={connect(mapStateToProps, mapDispatchToProps)(NotFound)}
        />
      </Switch>
    </Router>
  );
}

export default Main;
