import { Provider } from "react-redux";
import { store, persistor } from "./redux/app-redux";
import { PersistGate } from "redux-persist/integration/react";
import Main from "./Main";
// import TagManager from 'react-gtm-module'

// const tagManagerArgs = {
//   gtmId: 'GTM-NNDQFG3'
// }

// TagManager.initialize(tagManagerArgs)

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Main />
      </PersistGate>
    </Provider>
  );
}

export default App;
