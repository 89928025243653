import React from "react";
import Lottie from "lottie-react";
import PacmanLoader from 'react-spinners/PacmanLoader';

import '../style/LoadingIndicator.css';

import CookiesLoading from '../asset/loadingScreen/cookies_load.json'



export default class LoadingIndicator extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    render() {
        const {loading} = this.props;
        // console.log('loading', loading)
        return (
            <div className="loading">
                <PacmanLoader 
                size={30}
                color={'#000'}
                loading={loading}
                />
                {/* <Lottie
                    height={400}
                    width={400}
                    source={CookiesLoading}
                    autoPlay
                    loop

                /> */}
            </div>
        );
    }
}
