import React from "react";
import "../style/Footer.css";
import { FaFacebookF } from "react-icons/fa";
import { AiFillInstagram } from "react-icons/ai";

import logo from "../asset/logo.png";
import { Link } from "react-router-dom";

export default class Footer extends React.PureComponent {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <footer>
        <div className="container footer_container">
          <article>
            <Link>
              <img className="logo" src={logo} alt="footer logo" />
            </Link>
            <p>
              Ban Heang biscuits were the brainchild of Mr. Leow Tit Ban who
              started this company in 1997. Selling the renowned Tambun
              biscuits, it is available in various local flavors. Today, Ban
              Heang is a known brand not only in Penang but across the country
              as well.
            </p>
            <div className="footer_socials">
              <a
                href="https://www.facebook.com/banheang.msia"
                target="_blank"
                rel="noreferrer noopener"
              >
                <FaFacebookF />
              </a>
              <a
                href="https://www.instagram.com/banheang_msia/"
                target="_blank"
                rel="noreferrer noopener"
              >
                <AiFillInstagram />
              </a>
            </div>
          </article>

          <article>
            <h4>Permalinks</h4>
            <Link to="/about">About Us</Link>
            <Link to="/product">Products</Link>
            <Link to="/outlet">Outlets</Link>
            {/* <Link to="/about">Contact</Link> */}
          </article>
          {/* <article>
            <h4>Insights</h4>
            <Link to="/about">FAQs</Link>
            <Link to="/about">FAQs</Link>
            <Link to="/about">FAQs</Link>
            <Link to="/about">FAQs</Link>
          </article> */}
          <article>
            <h4>Get in Touch</h4>
            <Link to="/contact">Career</Link>
            {/* <Link to="/support">Support</Link> */}
          </article>
        </div>
        <div className="footer_copyright">
          <small>
            1997 - {new Date().getFullYear()} Ban Heang M Sdn Bhd &copy; All
            Right Reserved
          </small>
        </div>
      </footer>
    );
  }
}
