import React from "react";
import Carousel from "../component/Carousel";
import NavBar from "../component/NavBar";
import "../style/Home.css";
import firebase from "../config/firebase";
import Footer from "../component/Footer";
import Container from "../component/Container";
import Card from "../component/Card";
// import ContentImg from '../asset/contentImageHome.jpeg'
import ContentImgV2 from '../asset/contentImageHome_v2.jpeg'
import Cert from '../asset/cert.png'
import LoadingIndicator from "../component/LoadingIndicator";

const localhost = "http://localhost:3000/";

export default class Home extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,

      productData: [],
      bannerImg: [],
    };
  }

   componentDidMount() {
   this.callFunction()
  }

  async callFunction () {
    await this.getProductData();
    await this.getBannerImage();

setTimeout(() => {
   this.setState({loading: false})
}, 2000);
   
  }

  getProductData() {
    firebase
      .firestore()
      .collection("HomePageProductLists")
      .orderBy("orderBy")
      .onSnapshot((snapshot) => {
        let data = snapshot.docs.map((doc) => doc.data());
        this.setState({ productData: data });
      });
  }

  getBannerImage() {
    firebase
      .firestore()
      .collection("HomePageBannerImages")
      .orderBy("orderBy")
      .onSnapshot((snapshot) => {
        let data = snapshot.docs.map((doc) => doc.data());
        this.setState({ bannerImg: data });
      });
  }

  onClick(pLink) {
    // console.log(pLink)
    const anchor = document.createElement("a");
    anchor.href = pLink;
    anchor.target = "_blank";
    anchor.click();
  }

  render() {
    return (
      <Container>
        
        {this.state.loading ? <LoadingIndicator loading={this.state.loading}/> :
        <div className="container-fluid text-center bg-dark">

        <div className="contentImg">
            <img src={ContentImgV2} />
          </div>

          <Carousel data={this.state.bannerImg} />

          {/* <div className="contentImg">
            <img src={ContentImgV2} />
          </div> */}
          <section className="product">
            <h1 className="section-title">Our Best Seller</h1>
            <div className="product-container">
              {this.state.productData.map((data, index) => {
                return (
                  <div className="row">
                    <div className="card product-card">
                      <div className="card-body">
                        <div className="product-img">
                          <img src={data.img} alt={data.name} 
                          className="category_img"/>
                        </div>
                        <div className="productName">
                          <h3>{data.chineseName}</h3>
                          <h3>{data.name}</h3>
                        </div>
                        <div className="categoryName">
                          <p>{data.chineseCategory}</p>
                          <p>{data.category}</p>
                        </div>

                        {/* <div className="buttonContainer"> */}
                        <button
                          type="button"
                          onClick={() => this.onClick(data.purchaseLink)}
                          disabled={!data.purchaseLink}
                          className="buttonContainer">
                         {data.purchaseLink ? 'Check Out at Lazada' : 'Available at outlet only'}
                        </button>
                        {/* </div> */}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </section>

          <section className="cert">
            <h1 className="section-title">Our Certifications</h1>
            <div className="cert-img">
              <img src={Cert} />
            </div>
          </section>
        </div>}
      </Container>
    );
  }
}
