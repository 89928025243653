import React from "react";
import '../style/Footer.css';


import logo from '../asset/logo.png';
import { Link } from 'react-router-dom';

export default class Card extends React.PureComponent {

  render() {
    const {className, children} = this.props;
    return (
     <article className={`card ${className}`}>
        {children}
     </article>
    );
  }
}
