import React from "react";
import Container from "../component/Container";
import firebase from "../config/firebase";
import defaultLogo from "../asset/logo_b1.png";
import "../style/Product.css";

export default class AllProduct extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      category: [],
      products: [],
      productCat: [],
      latestProduct: [],
    };
  }

  componentDidMount() {
    this.getAllProducts();
  }

  getAllProducts() {
    firebase
      .firestore()
      .collection("ProductDetails")
      .orderBy("id")
      // .where("categoryId", "==", id)
      .onSnapshot((snapshot) => {
        let products = snapshot.docs.map((doc) => doc.data());
        this.setState({ products: products }, () => {
          // console.log("product caterr", this.state.products);
        });
      });
  }

  onClick(pLink) {
    // console.log(pLink);
    const anchor = document.createElement("a");
    anchor.href = pLink;
    anchor.target = "_blank";
    anchor.click();
  }

  // fetchProduct = () => {
  //   try {
  //     const products = commerce.products.list();
  //     console.log("fetching products", products.data);
  //     this.setState({ product: products });
  //   } catch (error) {
  //     console.log("Error fetching products", error);
  //   }
  // };

  render() {
    return (
      <Container>
        {/* <Header title="Our Products"></Header> */}
        <section className="category">
          <h2>{"All Products"}</h2>
          <div className="category-container">
            {this.state.products.map((item, index) => {
              return (
                <div key={index} className="category-col">
                  <div className="card category-card">
                    <div className="card-body">
                      <img
                        src={item.img === "" ? defaultLogo : item.img}
                        alt={item.name}
                      />
                      <div className="productName">
                        <h3>{item.chineseName}</h3>
                        <h3>{item.name + " (" + item.id + ")"}</h3>
                        <h3>{item.category + " (" + item.categoryId + ")"}</h3>
                        <h3>
                          {item.chineseCategory + " (" + item.orderBy + ")"}
                        </h3>
                      </div>
                    </div>
                    <button
                      type="button"
                      onClick={() => this.onClick(item.purchaseLink)}
                      disabled={!item.purchaseLink}
                      className="buttonContainer"
                    >
                      {item.purchaseLink
                        ? "Check Out at Lazada"
                        : "Available at outlet only"}
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
        </section>
      </Container>
    );
  }
}
