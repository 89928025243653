import React from "react";
import ProductItem from "./ProductItem";

export default class Product extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    const { products } = this.props;

    if (!Array.isArray(products)) {
      return null; // or render an alternative UI when products is not an array
    } else {
      // console.log(products);
    }

    return (
      <div className="products" id="products">
        {products.map((item) => {
          return <ProductItem key={item.id} product={item} />;
        })}
      </div>
    );
  }
}
