import React from "react";
import "../style/Carousel.css";
import { BsArrowLeftCircleFill, BsArrowRightCircleFill } from "react-icons/bs";

const localhost = "http://localhost:3000/";

export default class Carousel extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,

      slide: 0,
    };
  }

  componentDidMount() {
    setInterval(() => {
      this.nextSlide();
    }, 3000);
  }

  prevSlide = () => {
    let nextIndex =
      this.state.slide === 0
        ? this.props.data.length - 1
        : this.state.slide - 1;
    this.setState({ slide: nextIndex });
  };

  nextSlide = () => {
    let nextIndex =
      this.state.slide === this.props.data.length - 1
        ? 0
        : this.state.slide + 1;
    this.setState({ slide: nextIndex });
  };

  render() {
    const { data } = this.props;

    return (
      <div className="carousel">
        <BsArrowLeftCircleFill
          className="arrow arrow-left"
          onClick={() => this.prevSlide()}
        />
        {data.map((items, index) => {
          return (
            <img
              src={items.img}
              alt={items.name}
              key={index}
              className={
                this.state.slide === index ? "slide" : "slide slide-hidden"
              }
            />
          );
        })}
        <BsArrowRightCircleFill
          className="arrow arrow-right"
          onClick={() => this.nextSlide()}
        />
        <span className="indicators">
          {data.map((_, idx) => {
            return (
              <button
                key={idx}
                onClick={() => this.setState({ slide: idx })}
                className={
                  this.state.slide === idx
                    ? "indicator"
                    : "indicator indicator-inactive"
                }
              ></button>
            );
          })}
        </span>
      </div>
    );
  }
}
