import React from 'react';
import Card from './Card';
import '../style/Outlet.css'

import { BsFillHouseFill, BsFillClockFill, BsFillTelephoneFill } from 'react-icons/bs';
import { MdLocationOn } from 'react-icons/md';
import defaultLogo from '../asset/logo.png';

export default class Outlet extends React.PureComponent {

    onClick(mLink) {
        // console.log(mLink);
        const anchor = document.createElement("a");
        anchor.href = mLink;
        anchor.target = "_blank";
        anchor.click();
      }

    render() {
        const { data } = this.props;
        // console.log('data', data.maps)
        return (
            <Card className='outlet'>
                <div className='outlet_img'>
                    <img src={data.img === '-' ? defaultLogo : data.img} alt={data.name} />
                </div>
                <h3>{data.name}</h3>
                <p>{data.address}</p>
                <p>{data.hour}</p>
                <p>{data.tel} {data.fax === '-' ? null : ' / ' + data.fax}</p>

                <button
                          type="button"
                          onClick={() => this.onClick(data.maps)}
                        //   disabled={!data.purchaseLink}
                          className="buttonContainer">
                         {data.maps ? 'Open in Google Maps' : null}
                        </button>
            </Card>
        )
    }
}
