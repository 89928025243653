// import OutletMR from '../asset/Outlet/Outlet_MR.jpg'

export const outletsPenang = [
    {
        id: 1,
        name: 'Macalister Road (HQ)',
        address: '200, Jalan Macalister, 10400 Georgetown, Penang, Malaysia',
        hour: '9am – 6pm (Daily)',
        tel: '+604-2285018',
        fax: '+604-2295018',
        img: 'http://banheang.com.my/images/outlets/mr.jpg',
        maps: 'https://www.google.com/maps/place/Ban+Heang/@5.4166763,100.32086,17z/data=!4m14!1m7!3m6!1s0x304ac3bc3f1a9c4b:0xf5cb1debbbaac6a8!2sBan+Heang!8m2!3d5.416671!4d100.3234349!16s%2Fg%2F1hm5x4jjc!3m5!1s0x304ac3bc3f1a9c4b:0xf5cb1debbbaac6a8!8m2!3d5.416671!4d100.3234349!16s%2Fg%2F1hm5x4jjc?entry=ttu',
    },
    {
        id: 2,
        name: 'Penang Road',
        address: '245 & 247, Jalan Penang, 10000 Georgetown, Penang, Malaysia',
        hour: '9am – 6pm (Daily)',
        tel: '+604-2627125',
        fax: '+604-2616125',
        img: 'http://banheang.com.my/images/outlets/pr.jpg',
        maps: 'https://www.google.com/maps/place/Ban+Heang/@5.418891,100.3273283,17z/data=!4m10!1m2!2m1!1sBan+Heang!3m6!1s0x304ac39739376777:0x94755c81d2f72434!8m2!3d5.418891!4d100.3320919!15sCglCYW4gSGVhbmciA4gBAVoLIgliYW4gaGVhbmeSAQ5jaGluZXNlX3Bhc3RyeeABAA!16s%2Fg%2F11b6h_yrb8?entry=ttu',
    },
    {
        id: 3,
        name: 'New Lane',
        address: '40, Lorong Baru, 10400 Georgetown, Penang, Malaysia',
        hour: '1pm - 10pm (Daily) \n 1pm - 6pm (Wednesday)',
        tel: '+604-2280125',
        fax: '-',
        img: 'http://banheang.com.my/images/outlets/nl.jpg',
        maps: 'https://www.google.com/maps/place/Ban+Heang/@5.4143962,100.3214552,17z/data=!4m10!1m2!2m1!1sBan+Heang!3m6!1s0x304ac3be12e6bebd:0xeb5c594672af30c7!8m2!3d5.4143962!4d100.3262188!15sCglCYW4gSGVhbmciA4gBAVoLIgliYW4gaGVhbmeSAQtjb29raWVfc2hvcOABAA!16s%2Fg%2F11btt9mkk2?entry=ttu',
    },
    {
        id: 4,
        name: 'Gurney Plaza',
        address: 'Lot No.170-B1-33B, Gurney Plaza, Persiaran Gurney, 10250 Georgetown, Penang, Malaysia',
        hour: '10am - 10pm (Daily)',
        tel: '+604-2287125',
        fax: '-',
        img: 'http://banheang.com.my/images/outlets/gp.jpg',
        maps: 'https://www.google.com/maps/place/Ban+Heang+Gurney+Plaza/@5.4375344,100.3047227,17z/data=!4m10!1m2!2m1!1sBan+Heang!3m6!1s0x304ac303e9787ac5:0x7ecfbfe8c4ba6461!8m2!3d5.4375344!4d100.3094863!15sCglCYW4gSGVhbmciA4gBAVoLIgliYW4gaGVhbmeSAQtjb29raWVfc2hvcOABAA!16s%2Fg%2F11b7xp2r6j?entry=ttu',
    },
    {
        id: 5,
        name: 'Penang International Airport (Domestics Departure Hall)',
        address: 'Lot No.L2AR27, Domestic Departure/ Arrival (Airside), Level 2, Penang International Airport, 11900 Bayan Lepas, Pulau Pinang, Malaysia.',
        hour: '6am - 10pm (Daily)',
        tel: '+604-6445018',
        fax: '-',
        img: 'http://banheang.com.my/images/outlets/pia_d.jpg',
        maps: 'https://www.google.com/maps/place/Ban+Heang+-+Penang+International+Airport/@5.2927529,100.2606478,17z/data=!4m10!1m2!2m1!1sBan+Heang!3m6!1s0x304abfe406c35cc1:0xb09021dba4aa9faf!8m2!3d5.2927529!4d100.2654114!15sCglCYW4gSGVhbmciA4gBAVoLIgliYW4gaGVhbmeSAQtjb29raWVfc2hvcOABAA!16s%2Fg%2F11dxhgbpn0?entry=ttu',
    },
    {
        id: 6,
        name: 'Penang International Airport (Public Concourse)',
        address: 'Lot No.L2LFB03, Public Concourse (Landside) Level 2, Penang International Airport, 11900 Bayan Lepas, Pulau Pinang, Malaysia.',
        hour: '6am - 10pm (Daily)',
        tel: '+604-6380850',
        fax: '-',
        img: 'http://banheang.com.my/images/outlets/pia_pc.jpg',
        maps: 'https://www.google.com/maps/place/Ban+Heang+-+Penang+International+Airport/@5.2927529,100.2606478,17z/data=!4m10!1m2!2m1!1sBan+Heang!3m6!1s0x304abfe406c35cc1:0xb09021dba4aa9faf!8m2!3d5.2927529!4d100.2654114!15sCglCYW4gSGVhbmciA4gBAVoLIgliYW4gaGVhbmeSAQtjb29raWVfc2hvcOABAA!16s%2Fg%2F11dxhgbpn0?entry=ttu',
    },
]

export const outletsGenting = [
    {
        id: 7,
        name: 'Genting Awana Skyway',
        address: 'Lot No.3-14, Awana Skyway Station, 69000 Genting Highlands, Pahang, Malaysia',
        hour: '10am - 10pm (Daily)',
        tel: '+603-78902715',
        fax: '-',
        img: 'http://banheang.com.my/images/outlets/gta.jpg',
        maps: 'https://www.google.com/maps/place/Ben+Heang+Genting+@+Awana+SkyCentral/@3.4044526,101.7784163,17z/data=!3m1!4b1!4m6!3m5!1s0x31cc154f477d4eb3:0xc739774ada998611!8m2!3d3.4044473!4d101.7832872!16s%2Fg%2F11f6346559?entry=ttu',
    },
    {
        id: 8,
        name: 'Genting First World Hotel',
        address: 'Lot No.F/L1.2, Level 1, First World Plaza, First World Hotel, 69000 Genting Highlands, Pahang, Malaysia',
        hour: '10am - 10am (Daily)',
        tel: '+603-61013125',
        fax: '-',
        img: 'http://banheang.com.my/images/outlets/gt1w.jpg',
        maps: 'https://www.google.com/maps/place/Ban+Heang+Genting+@+First+World+Hotel/@3.4241446,101.7925373,17z/data=!3m1!4b1!4m6!3m5!1s0x31cc15fad1e9c293:0x341d27a91a4c9f0a!8m2!3d3.4241392!4d101.7951122!16s%2Fg%2F11g0g9j_bz?entry=ttu',
    },
]

export const outletsKl = [
    {
        id: 9,
        name: 'Kuala Lumpur International Airport 2 (Gateway)',
        address: 'L2-108, Level 2, Gateway@KLIA2, Jalan KLIA 2/1, KLIA, 64000 Sepang, Selangor, Malaysia',
        hour: '10am - 10pm (Daily)',
        tel: '+6016-4334204',
        fax: '-',
        img: 'http://banheang.com.my/images/outlets/klia2_g.jpg',
        maps: 'https://www.google.com/maps?gs_lcrp=EgZjaHJvbWUyBggAEEUYOTIPCAEQLhgNGK8BGMcBGIAEMgkIAhAAGA0YgAQyCQgDEAAYDRiABDIJCAQQABgNGIAEMgoIBRAAGAUYDRgeMgoIBhAAGAUYDRgeMgoIBxAAGAgYDRgeMgoICBAAGAgYDRge0gEINDY4MmowajSoAgCwAgA&q=klia2+gateway&um=1&ie=UTF-8&sa=X&ved=2ahUKEwjAid-d0riCAxVgxDgGHSOKDMYQ_AUoAXoECAEQAw',
    },
    {
        id: 10,
        name: 'Kuala Lumpur International Airport 2 (Gateway @ Kiosk)',
        address: 'Lot No. L3-39 ,Level 3,Gateway@KLIA2, Jalan KLIA 2/1, KLIA, 64000 Sepang, Selangor, Malaysia',
        hour: '6am - 10pm (Daily)',
        tel: '-',
        fax: '-',
        img: 'http://banheang.com.my/images/outlets/klia_k.jpg',
        maps: 'https://www.google.com/maps?gs_lcrp=EgZjaHJvbWUyBggAEEUYOTIPCAEQLhgNGK8BGMcBGIAEMgkIAhAAGA0YgAQyCQgDEAAYDRiABDIJCAQQABgNGIAEMgoIBRAAGAUYDRgeMgoIBhAAGAUYDRgeMgoIBxAAGAgYDRgeMgoICBAAGAgYDRge0gEINDY4MmowajSoAgCwAgA&q=klia2+gateway&um=1&ie=UTF-8&sa=X&ved=2ahUKEwjAid-d0riCAxVgxDgGHSOKDMYQ_AUoAXoECAEQAw',
    },
]

export const outletsMalacca = [
    {
        id: 11,
        name: 'Melaka Jonker Street',
        address: '36, Jalan Hang Jebat, 75200, Melaka, Malaysia',
        hour: '9am - 6pm (Monday - Thursday) \n 9am - 11pm (Friday - Sunday)',
        tel: '+606-2868686',
        fax: '-',
        img: 'http://banheang.com.my/images/outlets/mjs.jpg',
        maps: 'https://www.google.com/maps/place/Ban+Heang+Melaka+Jongker+Street/@2.19641,102.2445519,17z/data=!4m6!3m5!1s0x31d1f181d8fd36e1:0xbfaf7bd25c840014!8m2!3d2.1953647!4d102.2477062!16s%2Fg%2F11j4qls3sk?entry=ttu',
    },
]