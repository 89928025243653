import React from "react";
import NavBar from "./NavBar";
import Footer from "./Footer";

export default class Container extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      activeLanguage: "English",
      languageId: "en",
    };

    this.language = [
      { id: "en", name: "English" },
      { id: "zh", name: "中文" },
      { id: "my", name: "Malay" },
      { id: "jp", name: "日本語" },
    ];
  }

  componentDidMount() {}

  switchLanguage = (id) => {
    let currentLanguage = this.language
      .filter((item) => {
        return item.id === id;
      })
      .map((i) => {
        return i.name;
      });
    // console.log(currentLanguage);
    this.setState({ activeLanguage: currentLanguage, languageId: id }, () => {
      this.props.setLanguage(id);
    });
  };

  render() {
    const { showBanner } = this.props;
    return (
      <div>
        <NavBar />
        <div className="main-content">{this.props.children}</div>
        <Footer />
      </div>
    );
  }
}
